<!--
 * @Author: your name
 * @Date: 2021-03-04 14:45:23
 * @LastEditTime: 2021-03-09 17:50:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\views\doctor\list.vue
-->
<template>
  <div class="doctor">
    <DoctorList :doctor-list="doctorList" :hylx="hylx" />
    <Brand />
  </div>
</template>
<script>
import DoctorList from '../../components/category/DoctorList'
import Brand from '../../components/category/brand'
import {appointModel} from '../../api/appoint'
export default {
  components:{
    DoctorList,
    Brand
  },
  data(){
    return{
      doctorList:[],
      hylx:''
    }
  },
  mounted(){
    const {hylx}=this.$route.query
    this.hylx=hylx
    this.getDepDocList()
  },
   methods:{
     async getDepDocList(){
       // console.dir(this.$route,"理由")
       const {yljgdm,ksdm,hylx}=this.$route.query
      const result=await appointModel.depDoc({yljgdm,ksdm,hylx})
      // console.log(result,"r")
      this.doctorList=result.data|| []
     }
  }
}
</script>
<style scoped lang='less'>
@import '../../assets/styles/variable.less';
div {
    box-sizing: border-box;
    }
.doctor{
  .title{
    margin-bottom: 37px;
    line-height: 45px;
    font-size: @font-size-32;
    font-weight: bold;
    color: @dark-font-color;
  }
}
</style>
