<!--
 * @Author: your name
 * @Date: 2021-03-04 14:57:58
 * @LastEditTime: 2021-03-09 17:26:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\components\category\DoctorList.vue
-->
<template>
  <div>
    <div v-for="(item,index) in doctorList" :key="index" class="introduce" @click="dotctorJump(item)">
      <div class="body">
        <div class="info">
          <div class="pic">
            <img class="img" :src="item.txljdz">
          </div>
          <div class="con">
            <div class="name">
              {{ item.ysxm }}
              <span class="post">{{ item.zc==null?'无':item.zc }}</span>
            </div>
            <div class="departments">
              {{ item.ksmc }}
            </div>
          </div>
        </div>
        <div class="desc packup">
          <span class="label">
            擅长：
          </span>
          {{ item.yssc==null?'无':item.yssc }}
        </div>
        <!--      <div class="desc packup">-->
        <!--        <div class="label">-->
        <!--          简介：-->
        <!--        </div>-->
        <!--        {{ item.ysjj }}-->
        <!--      </div>-->
        <div class="others">
          <div class="other-item">
            好评率：
            <span class="num">{{ item.hpl==null?'无':item.hpl }}</span>
          </div>
          <div class="other-item">
            问诊量：
            <span class="num">{{ item.wzl==null?'无':item.wzl }}</span>
          </div>
        </div>
      </div>
      <div class="border" />
    </div>
  </div>
</template>
<script>
export default {
props:{
doctorList:{
    type: Array,
    default: ()=>([])
},
  hylx:{
  type:String,
    default:''
  }
},
data(){
  return{

  }
},
methods:{
  dotctorJump(item){
    // console.log(item,'item')
    // console.log(this.hylx,"hyx")
    const ysgh=item.ysgh
    const ksdm=item.ksdm
    const yljgdm=item.yljgdm
    this.$router.push({
      path: '/doctor/detail',
        query: {
          yljgdm:yljgdm,
          ksdm:ksdm,
          ysgh:ysgh,
          hylx:this.hylx
        }
    })

  }
}
}
</script>
<style scoped lang='less'>
.introduce {
  line-height: 33px;
    .body {
      padding: 28px 32px 28px 32px;
      .title {
        margin-bottom: 28px;
      }

      .info {
        display: flex;
        margin-bottom: 20px;
      }

      .pic,
      .img {
        width: 102px;
        height: 102px;
        border-radius: 50%;
        background: #ccc;
      }

      .con {
        flex: 1;
        padding-left: 32px;
        line-height: 33px;
        font-size: 28px;

        .name {
          align-items: center;
          margin-bottom: 19px;
          line-height: 45px;
          font-size: 35px;
          font-weight: bold;
          color: #3d3d3d;

          .post {
            margin-left: 14px;
            font-weight: normal;
            font-size: 28px;
          }
        }
      }

      .desc {
        margin-bottom: 24px;
        font-size: 28px;
        line-height: 1.5;
        margin-left: 126px;
        color: #666666;

        &:last-child {
          margin-bottom: 0;
        }

        .label {
          font-weight: bold;
          color: #1c9cf6;
        }

      }

      .packup {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .others {
        display: flex;
        margin-left: 126px;
        align-items: center;

        .other-item {
          margin-right: 48px;
          font-size: 24px;

          .num {
            color: #1c9cf6;
          }
        }

      }
    }
  .border{
   border-bottom: 1px solid #EEEEEE;
    width: 100%;
    padding-top:1px;
  }
}
</style>
