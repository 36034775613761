/*
 * @Author: your name
 * @Date: 2021-03-06 16:33:46
 * @LastEditTime: 2021-03-15 15:24:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\api\constant.js
 */
import { Http } from '@/utils/http'
import {combineUrl} from '../utils/index'
const URL_PREFIX = '/user'
export class AppointModel extends Http {
  //查询科室
  selectDep(params){
    return this.post(combineUrl(URL_PREFIX, '/tableNo/selectDep'),params,{isFormData:true})
  }
  //查询科室医生列表
  depDoc(params){
    return this.post(combineUrl(URL_PREFIX,'/depDoc/select'),params,{isFormData:true})
  }
////给医生添加关注
concern(params){
  return this.post(combineUrl(URL_PREFIX,'/mydoctor/insert'),params)
}
// //给医生取消关注
unconcern(params){
  return this.post(combineUrl(URL_PREFIX,'/mydoctor/dalete'),params)
}
//查询医生评价/user/depDoc/evaluate
getWrite(params){
  return this.post(combineUrl(URL_PREFIX,'/depDoc/evaluate'),params,{isFormData:true})
}
//查询科室排班
scheduling(params){
  return this.post(combineUrl(URL_PREFIX,'/noSource/map'),params,{isFormData:true})
}
appointmentDetail(params){
  return this.post("/user/appointment/select", params , { isFormData:true })
}
//查询卡号
patientCard(params){
  return this.post(combineUrl(URL_PREFIX,'/patientinfo/patientDetail'),params,{isFormData:true })
}
//预约
appoint(params){
  return this.post(combineUrl(URL_PREFIX,'/appointment/appoint'),params)
}
//取消预约
 cancel(params){
  return this.post(combineUrl(URL_PREFIX,'/appointment/cancel'),params,{isFormData:true})
}
//取消预约
 countCancel(params) {
  return this.post(combineUrl(URL_PREFIX,'/appointment/count_cancel'),params,{isFormData:true})
}
//查询挂号详情
queryRegisterDetail(registerId){
  return this.post('/user/registerOrder/register-order-message',{registerId}, {isFormData:true })
}
  //取消挂号订单cancelRegisterOrder
cancelRegistration(params) {
  return this.post(combineUrl(URL_PREFIX,'registerOrder/cancelRegisterOrder'),params,{isFormData:true})
}
//创建挂号单
createRegisterOrder(params) {
  return this.post(combineUrl(URL_PREFIX,'/registerOrder/createRegisterOrder'),params,{isFormData:true})
}
}
export const appointModel = new AppointModel()
