<!--
 * @Author: your name
 * @Date: 2021-03-04 15:04:47
 * @LastEditTime: 2021-03-04 15:06:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\components\category\brand.vue
-->
<template>
  <div class="brand">
    - 医精德诚，廉洁在心 -
  </div>
</template>
<script>
export default {

}
</script>
<style scoped lang='less'>
    div{
       box-sizing: border-box;
    }
    .brand {
        line-height: 33px;
        font-size: 24px;
        color: #999;
        text-align: center;
        margin-top: 28px;
    }
</style>
